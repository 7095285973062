import { FunctionComponent, useState, useCallback, useEffect } from "react";
import DropdownMenuMultiSelect from "./DropdownMenuMultiSelect";
import PortalPopup from "../Portals/PortalPopup";
import styles from "./MapPageFilter.module.css";
import { Modal, Button, Col } from "react-bootstrap";

interface MapPageFilterProps {
  closeFilter: () => void;
  passedOffersCount: (count: number) => void;
  setliftGate: (value: string) => void;
  setbodyType: (value: string) => void;
  setdoorType: (value: string) => void;
  setlength: (value: string) => void;
  settransmission: (value: string) => void;
  setothers: (value: string) => void;
  setGoal: (value: string) => void;
  setMinimumPrice: (value: number) => void;
  setMaximumPrice: (value: number) => void;
  setmenufacturer: (value: string) => void;
  setMileageFrom: (value: number) => void;
  setMileageTo: (value: number) => void;
  setIsFilterReset: (value: boolean) => void;
  setIsPortalOpen: (value: boolean) => void;
  liftGate: string;
  bodyType: string;
  doorType: string;
  length: string;
  transmission: string;
  others: string;
  goal: string;
  minimumPrice: number;
  maximumPrice: number;
  menufacturer: string;
  mileageFrom: number;
  mileageTo: number;
  isFilterReset: boolean;
}

type SetStateFunction = (value: string) => void;

export const MapPageFilter: FunctionComponent<MapPageFilterProps> = ({
  closeFilter,
  setliftGate,
  setbodyType,
  setdoorType,
  setlength,
  settransmission,
  setothers,
  setGoal,
  setMinimumPrice,
  setMaximumPrice,
  setmenufacturer,
  setMileageFrom,
  setMileageTo,
  setIsFilterReset,
  setIsPortalOpen,
  liftGate,
  bodyType,
  doorType,
  length,
  transmission,
  others,
  goal,
  minimumPrice,
  maximumPrice,
  menufacturer,
  mileageFrom,
  mileageTo,
  isFilterReset,
}) => {
  const [isDropdownMenuMultiSelectOpen, setDropdownMenuMultiSelectOpen] =
    useState<boolean>(false);
  const [isDropdownMenuMultiSelect1Open, setDropdownMenuMultiSelect1Open] =
    useState<boolean>(false);
  const handleClose = () => {
    closeFilter();
  };

  const [goalForRent, setGoalForRent] = useState<boolean>(
    goal.includes("For rent") ? true : false
  );
  const [goalForLease, setGoalForLease] = useState<boolean>(
    goal.includes("For lease") ? true : false
  );
  const [goalForLeaseForPurchase, setGoalForLeaseForPurchase] = useState<boolean>(
    goal.includes("Lease For Purchase") ? true : false
  );
  const [bodyTypeReefer, setBodyTypeReefer] = useState<boolean>(
    bodyType.includes("reefer") ? true : false
  );
  const [bodyTypeFlatbed, setBodyTypeFlatbed] = useState<boolean>(
    bodyType.includes("flatbed") ? true : false
  );
  const [bodyTypeDry, setBodyTypeDry] = useState<boolean>(
    bodyType.includes("dryVan") ? true : false
  );
  const [doorTypeSwing, setDoorTypeSwing] = useState<boolean>(
    doorType.includes("swing") ? true : false
  );
  const [doorTypeRoll, setDoorTypeRoll] = useState<boolean>(
    doorType.includes("roll") ? true : false
  );
  const [liftGateRailGate, setLiftGateRailGate] = useState<boolean>(
    liftGate.includes("rail-gate") ? true : false
  );
  const [liftGateTuckUnder, setLiftGateTuckUnder] = useState<boolean>(
    liftGate.includes("truck-under") ? true : false
  );
  const [liftGateNoLiftGate, setLiftGateNoLiftGate] = useState<boolean>(
    liftGate.includes("no-lift-gate") ? true : false
  );
  const [lengthSmall, setLengthSmall] = useState<boolean>(
    length.includes("small") ? true : false
  );
  const [lengthLarge, setLengthLarge] = useState<boolean>(
    length.includes("large") ? true : false
  );
  const [transmissionTypeManual, setTransmissionTypeManual] = useState<boolean>(
    transmission.includes("manual") ? true : false
  );
  const [transmissionTypeAutomatic, setTransmissionTypeAutomatic] = useState<boolean>(
    transmission.includes("automatic") ? true : false
  );
  const [cargoSecurementTrackRailGate, setCargoSecurementTrackRailGate] =
    useState<boolean>(false);
  const [cargoSecurementTrackTuckUnder, setCargoSecurementTrackTuckUnder] =
    useState<boolean>(false);
  const [cargoSecurementTrackNoLiftGate, setCargoSecurementTrackNoLiftGate] =
    useState<boolean>(false);

  const [yearUpTo2010, setYearUpTo2010] = useState<boolean>(false);
  const [yearUpTo2015, setYearUpTo2015] = useState<boolean>(false);
  const [yearUpTo2020, setYearUpTo2020] = useState<boolean>(false);
  const [yearUpTo2023, setYearUpTo2023] = useState<boolean>(false);
  const [mileageUpTo100k, setMileageUpTo100k] = useState<boolean>(false);
  const [mileageUpTo200k, setMileageUpTo200k] = useState<boolean>(false);
  const [mileageOver300k, setMileageOver300k] = useState<boolean>(false);
  const [yearFrom, setYearFrom] = useState<number>(0);
  const [yearTo, setYearTo] = useState<number>(0);
  const [crewCab, setCrewCab] = useState<boolean>(
    others.includes("crewCab") ? true : false
  );
  const [ramp, setRamp] = useState<boolean>(others.includes("ramp") ? true : false);
  const [dockHeight, setDockHeight] = useState<boolean>(
    others.includes("dockHeight") ? true : false
  );
  const [iftaCompliant, setIftaCompliant] = useState<boolean>(
    others.includes("iftaCompliant") ? true : false
  );
  const [reservationRequirementsYes, setReservationRequirementsYes] =
    useState<boolean>(false);
  const [reservationRequirementsNo, setReservationRequirementsNo] =
    useState<boolean>(false);
  const [reservationRequirementsEither, setReservationRequirementsEither] =
    useState<boolean>(false);

  const [selectedManufacturers, setSelectedManufacturers] = useState<string[]>(
    []
  );

  const [premiumOwnerOnly, setPremiumOwnerOnly] = useState<boolean>(false);
  const [managingMyFilters, setManagingMyFilters] = useState<boolean>(false);

  const handleTagClick = (tagType: string) => {};

  const closeDropdownMenuMultiSelect = useCallback(() => {
    setDropdownMenuMultiSelectOpen(false);
  }, []);

  const closeDropdownMenuMultiSelect1 = useCallback(() => {
    setDropdownMenuMultiSelect1Open(false);
  }, []);

  const resetAllCheckboxes = () => {
    const checkboxes = document.querySelectorAll(`.${styles.checkbox1}`);

    checkboxes.forEach((checkbox) => {
      const checkboxInput = checkbox as HTMLInputElement;
      checkboxInput.checked = false;
    });

    setSelectedManufacturers([]);
  };

  const setFilters = (
    condition: boolean,
    filterStr: string,
    setState: SetStateFunction,
    filterName: string
  ) => {
    if (!condition) {
      setState(`${filterName.replace(/`${filterStr}`/g, "")}`);
      setState(
        `${filterName ? `${filterName}, ${filterStr}` : `${filterStr}`}`
      );
    } else {
      setState(`${filterName.replace(`${filterStr}`, "")}`);
    }
  };

  const resetAllStates = () => {
    setMinimumPrice(0);
    setMaximumPrice(0);
    setGoalForRent(false);
    setGoalForLease(false);
    setGoalForLeaseForPurchase(false);
    setBodyTypeReefer(false);
    setBodyTypeFlatbed(false);
    setBodyTypeDry(false);
    setDoorTypeSwing(false);
    setDoorTypeRoll(false);
    setLiftGateRailGate(false);
    setLiftGateTuckUnder(false);
    setLiftGateNoLiftGate(false);
    setLengthSmall(false);
    setLengthLarge(false);
    setTransmissionTypeManual(false);
    setTransmissionTypeAutomatic(false);
    setCargoSecurementTrackRailGate(false);
    setCargoSecurementTrackTuckUnder(false);
    setCargoSecurementTrackNoLiftGate(false);
    setYearUpTo2010(false);
    setYearUpTo2015(false);
    setYearUpTo2020(false);
    setYearUpTo2023(false);
    setMileageUpTo100k(false);
    setMileageUpTo200k(false);
    setMileageOver300k(false);
    setMileageFrom(0);
    setMileageTo(0);
    setYearFrom(0);
    setYearTo(0);
    setCrewCab(false);
    setRamp(false);
    setDockHeight(false);
    setIftaCompliant(false);
    setReservationRequirementsYes(false);
    setReservationRequirementsNo(false);
    setReservationRequirementsEither(false);
    resetAllCheckboxes();
    setPremiumOwnerOnly(false);
    setManagingMyFilters(false);
    setmenufacturer("");
    setliftGate("");
    setbodyType("");
    setdoorType("");
    setlength("");
    settransmission("");
    setothers("");
    setGoal("");
    setMinimumPrice(0);
    setMaximumPrice(0);
    setmenufacturer("");
    setMileageFrom(0);
    setMileageTo(0);
    setIsFilterReset(false);
  };

  useEffect(() => {
    if (isFilterReset) {
      resetAllStates();
    }
  }, [isFilterReset]);

  return (
    <>
      <Modal
        show={true}
        onHide={() => setIsPortalOpen(false)}
        size="lg"
        className="filters-modal"
      >
        <div>
          <Modal.Header closeButton className="mx-2">
            <Modal.Title>
              <b>Filters</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5">
            <Col>
              <div className={styles.rectangleGroup}>
                <div className={styles.frameParent6}>
                  <div className={styles.userNameParent}>
                    <div className={`${styles.userName1} mb-3`}>
                      Price range
                    </div>
                    <div className={`${styles.userName2} mb-3`}>
                      Average price per truck: 140$
                    </div>
                  </div>
                  <img
                    className={styles.image80Icon}
                    alt=""
                    src="/generics/image-80@2x.png"
                  />
                  <div className={styles.frameWrapper4}>
                    <div className={`${styles.inputWithLabelParent} `}>
                      <div className={`${styles.inputWithLabel} `}>
                        <div className={styles.reefer}>Minimum price</div>
                        <div className={`${styles.inputWithPrefixessuffixes}`}>
                          <div
                            className={styles.basicInput}
                            style={{
                              border: "none",
                              height: "99%",
                              backgroundColor: "transparent",
                            }}
                          >
                            <div className={styles.inputContainer}>
                              <span className={styles.dollarSign}>$</span>
                              <input
                                className={`${styles.inputText} ${styles.moneyField}`}
                                placeholder={minimumPrice === 0 ? "From" : ""}
                                type="number"
                                value={
                                  minimumPrice === 0 ? "From" : minimumPrice
                                }
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setMinimumPrice(Number(event.target.value));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className={`${styles.div8}`}>-</div> */}
                      <div className={`${styles.inputWithLabel} `}>
                        <div className={styles.reefer}>Maximum price</div>
                        <div className={styles.inputWithPrefixessuffixes}>
                          <div
                            className={styles.basicInput}
                            style={{
                              border: "none",
                              height: "99%",
                              backgroundColor: "transparent",
                            }}
                          >
                            <div className={styles.inputContainer}>
                              <span className={styles.dollarSign}>$</span>
                              <input
                                className={`${styles.inputText} ${styles.moneyField}`}
                                placeholder={maximumPrice === 0 ? "To" : ""}
                                type="number"
                                value={maximumPrice === 0 ? "To" : maximumPrice}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setMaximumPrice(Number(event.target.value));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.inputTextParent}>
                <div className={`${styles.inputTextParent1} mt-3`}>
                  <div>
                    <h5>
                      <div className={`${styles.inputText11} `}>Goal:</div>
                    </h5>
                  </div>
                  <div>
                    <div
                      className={`${styles.tag11} hoverButtons ${
                        styles.tagParent
                      } float-left m-2 ${
                        goalForRent ? `${styles.tag27} ${styles.tag11}` : ""
                      }`}
                      onClick={() => setGoalForRent(!goalForRent)}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div
                        className={styles.reefer}
                        onClick={() => {
                          setGoalForRent(!goalForRent);
                          setFilters(goalForRent, "For rent", setGoal, goal);
                        }}
                      >
                        For rent
                      </div>
                      {goalForRent && (
                        <img
                          className={styles.iconsolidx1}
                          alt=""
                          src="/generics/close.png"
                        />
                      )}
                    </div>
                    <div
                      className={`${styles.tag11} hoverButtons ${
                        styles.tagParent
                      } float-left m-2 ${
                        goalForLease ? `${styles.tag27} ${styles.tag11}` : ""
                      }`}
                      onClick={() => setGoalForLease(!goalForLease)}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div
                        className={styles.reefer}
                        onClick={() => {
                          setGoalForLease(!goalForLease);
                          setFilters(goalForLease, "For lease", setGoal, goal);
                        }}
                      >
                        For lease
                      </div>
                      {goalForLease && (
                        <img
                          className={styles.iconsolidx1}
                          alt=""
                          src="/generics/close.png"
                        />
                      )}
                    </div>
                    <div
                      className={`${styles.tag11} hoverButtons ${
                        styles.tagParent
                      } float-left m-2 ${
                        goalForLeaseForPurchase
                          ? `${styles.tag27} ${styles.tag11}`
                          : ""
                      }`}
                      onClick={() =>
                        setGoalForLeaseForPurchase(!goalForLeaseForPurchase)
                      }
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div
                        className={styles.reefer}
                        onClick={() => {
                          setGoalForLeaseForPurchase(!goalForLeaseForPurchase);
                          setFilters(
                            goalForLeaseForPurchase,
                            "Lease For Purchase",
                            setGoal,
                            goal
                          );
                        }}
                      >
                        Lease for purchase
                      </div>
                      {goalForLeaseForPurchase && (
                        <img
                          className={styles.iconsolidx1}
                          alt=""
                          src="/generics/close.png"
                        />
                      )}
                    </div>
                    <div className={styles.tag3}>
                      <img
                        className={styles.earnRevenueFromYourIdleFl3}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer}>Dry</div>
                    </div>
                  </div>
                </div>

                <div className={`${styles.frameChild30} mt-5`} />
                <div className={styles.inputTextGroup}>
                  <div className={`${styles.inputText12} col-3`}>Length</div>
                  <div className={styles.tagGroup}>
                    <div className={styles.tag4}>
                      <img
                        className={styles.earnRevenueFromYourIdleFl3}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer}>Small</div>
                    </div>
                    <div className={styles.tag4}>
                      <img
                        className={styles.earnRevenueFromYourIdleFl3}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer}>Large</div>
                    </div>
                  </div>
                </div>

                <div className={styles.userNameGroup}>
                  <h5>
                    <div className={`${styles.userName1} mt-4`}>Body: </div>
                  </h5>
                  <div className={`${styles.inputTextParent1}`}>
                    <div>
                      <h5>
                        <div className={`${styles.inputText11}`}>
                          Body Type:
                        </div>
                      </h5>
                    </div>
                    <div>
                      <div
                        className={`${styles.tag11} hoverButtons ${
                          styles.tagParent
                        } float-left m-2 ${
                          bodyTypeReefer
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() => {
                          setBodyTypeReefer(!bodyTypeReefer);
                          setFilters(
                            bodyTypeReefer,
                            "reefer",
                            setbodyType,
                            bodyType
                          );
                        }}
                      >
                        <div className={styles.reefer}>Reefer</div>
                        {bodyTypeReefer && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} hoverButtons ${
                          styles.tagParent
                        } float-left m-2 ${
                          bodyTypeFlatbed
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() => {
                          setBodyTypeFlatbed(!bodyTypeFlatbed);
                          setFilters(
                            bodyTypeFlatbed,
                            "flatbed",
                            setbodyType,
                            bodyType
                          );
                        }}
                      >
                        <div className={styles.reefer}>Flatbed</div>
                        {bodyTypeFlatbed && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>

                      <div
                        className={`${styles.tag11} hoverButtons ${
                          styles.tagParent
                        } float-left m-2 ${
                          bodyTypeDry ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setBodyTypeDry(!bodyTypeDry);
                          setFilters(
                            bodyTypeDry,
                            "dryVan",
                            setbodyType,
                            bodyType
                          );
                        }}
                      >
                        <div className={styles.reefer}>Dry</div>
                        {bodyTypeDry && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Door Type:</div>
                      </h5>
                    </div>
                    <div className={`${styles.tagParent}`}>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          doorTypeSwing ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setDoorTypeSwing(!doorTypeSwing);
                          setFilters(
                            doorTypeSwing,
                            "swing",
                            setdoorType,
                            doorType
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Swing</div>
                        {doorTypeSwing && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          doorTypeRoll ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setDoorTypeRoll(!doorTypeRoll);
                          setFilters(
                            doorTypeRoll,
                            "roll",
                            setdoorType,
                            doorType
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Roll</div>
                        {doorTypeRoll && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Lift Gate:</div>
                      </h5>
                    </div>
                    <div className={styles.tagParent}>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          liftGateRailGate
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() => {
                          setLiftGateRailGate(!liftGateRailGate);
                          setFilters(
                            liftGateRailGate,
                            "rail-gate",
                            setliftGate,
                            liftGate
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Rail Gate</div>
                        {liftGateRailGate && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          liftGateTuckUnder
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() => {
                          setLiftGateTuckUnder(!liftGateTuckUnder);
                          setFilters(
                            liftGateTuckUnder,
                            "truck-under",
                            setliftGate,
                            liftGate
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Tuck Under</div>
                        {liftGateTuckUnder && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          liftGateNoLiftGate
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() => {
                          setLiftGateNoLiftGate(!liftGateNoLiftGate);
                          setFilters(
                            liftGateNoLiftGate,
                            "no-lift-gate",
                            setliftGate,
                            liftGate
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>No Lift Gate</div>
                        {liftGateNoLiftGate && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Length:</div>
                      </h5>
                    </div>
                    <div className={styles.tagParent}>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          lengthSmall ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setLengthSmall(!lengthSmall);
                          setFilters(lengthSmall, "small", setlength, length);
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Small</div>
                        {lengthSmall && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          lengthLarge ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setLengthLarge(!lengthLarge);
                          setFilters(lengthLarge, "large", setlength, length);
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Large</div>
                        {lengthLarge && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>
                          Transmission Type:
                        </div>
                      </h5>
                    </div>
                    <div className={styles.tagParent}>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          transmissionTypeManual
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() => {
                          setTransmissionTypeManual(!transmissionTypeManual);
                          setFilters(
                            transmissionTypeManual,
                            "manual",
                            settransmission,
                            transmission
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Manual</div>
                        {transmissionTypeManual && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          transmissionTypeAutomatic
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() => {
                          setTransmissionTypeAutomatic(
                            !transmissionTypeAutomatic
                          );
                          setFilters(
                            transmissionTypeAutomatic,
                            "automatic",
                            settransmission,
                            transmission
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Automatic</div>
                        {transmissionTypeAutomatic && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent7}>
                    <div>
                      <h5>
                        <div className={`${styles.inputText11} m-3`}>
                          Mileage:
                        </div>
                      </h5>
                    </div>
                    <div className={`${styles.tagParent}`}>
                      <div className={styles.frameParent8}>
                        <div className={styles.basicInputContainer}>
                          <div className={styles.basicInput}>
                            <input
                              className={`${styles.inputText}`}
                              placeholder={mileageFrom == 0 ? "From" : ""}
                              type="number"
                              value={mileageFrom === 0 ? "From" : mileageFrom}
                              onChange={(event: any) => {
                                setMileageFrom(event.target.value);
                              }}
                            ></input>
                          </div>
                          <div className={styles.basicInput1}>
                            <input
                              className={`${styles.inputText}`}
                              placeholder={mileageTo === 0 ? "To" : ""}
                              type="number"
                              value={mileageTo === 0 ? "To" : mileageTo}
                              onChange={(event: any) => {
                                setMileageTo(event.target.value);
                              }}
                            ></input>
                          </div>
                        </div>
                        <div className={styles.tandemTractor}>k</div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}></div>
                      </h5>
                    </div>
                    <div className="">
                      <div className={styles.tagParent8}>
                        <div
                          className={`${styles.tag11} float-left m-2 ${
                            mileageUpTo100k
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setMileageUpTo100k(!mileageUpTo100k);
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>Up to 100k</div>
                          {mileageUpTo100k && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="/generics/close.png"
                            />
                          )}
                        </div>
                        <div
                          className={`${styles.tag11} float-left m-2 ${
                            mileageUpTo200k
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setMileageUpTo200k(!mileageUpTo200k);
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>Up to 200k</div>
                          {mileageUpTo200k && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="/generics/close.png"
                            />
                          )}
                        </div>
                        <div
                          className={`${styles.tag11} float-left m-2 ${
                            mileageOver300k
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setMileageOver300k(!mileageOver300k);
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>Over 300k</div>
                          {mileageOver300k && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="/generics/close.png"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent7}>
                    <div>
                      <h5>
                        <div className={`${styles.inputText11} m-3`}>Year:</div>
                      </h5>
                    </div>
                    <div className={styles.tagParent}>
                      <div className={styles.frameParent8}>
                        <div className={styles.basicInputContainer}>
                          <div className={styles.basicInput}>
                            <input
                              className={`${styles.inputText}`}
                              placeholder={yearFrom === 0 ? "From" : ""}
                              type="number"
                              value={yearFrom === 0 ? "From" : yearFrom}
                              onChange={(event: any) => {
                                setYearFrom(event.target.value);
                              }}
                            ></input>
                          </div>
                          <div className={styles.basicInput1}>
                            <input
                              className={`${styles.inputText}`}
                              placeholder={yearTo === 0 ? "To" : ""}
                              type="number"
                              value={yearTo === 0 ? "To" : yearTo}
                              onChange={(event: any) => {
                                setYearTo(event.target.value);
                              }}
                            ></input>
                          </div>
                        </div>
                        <div className={styles.tandemTractor}>year</div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}></div>
                      </h5>
                    </div>
                    <div className="">
                      <div className={styles.tagParent8}>
                        <div
                          className={`${styles.tag11} float-left m-2  ${
                            yearUpTo2010
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setYearUpTo2010(!yearUpTo2010);
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>Up to 2010</div>
                          {yearUpTo2010 && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="/generics/close.png"
                            />
                          )}
                        </div>
                        <div
                          className={`${styles.tag11} float-left m-2  ${
                            yearUpTo2015
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setYearUpTo2015(!yearUpTo2015);
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>Up to 2015</div>
                          {yearUpTo2015 && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="/generics/close.png"
                            />
                          )}
                        </div>
                        <div
                          className={`${styles.tag11} float-left m-2  ${
                            yearUpTo2020
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setYearUpTo2020(!yearUpTo2020);
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>Up to 2020</div>
                          {yearUpTo2020 && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="/generics/close.png"
                            />
                          )}
                        </div>
                        <div
                          className={`${styles.tag11} float-left m-2 ${
                            yearUpTo2023
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setYearUpTo2023(!yearUpTo2023);
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>Up to 2023</div>
                          {yearUpTo2023 && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="/generics/close.png"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Others:</div>
                      </h5>
                    </div>
                    <div className={styles.tagParent}>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          crewCab ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setCrewCab(!crewCab);
                          setFilters(crewCab, "crewCab", setothers, others);
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Crew Cab</div>
                        {crewCab && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          ramp ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setRamp(!ramp);
                          setFilters(ramp, "ramp", setothers, others);
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Ramp</div>
                        {ramp && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          dockHeight ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setDockHeight(!dockHeight);
                          setFilters(
                            dockHeight,
                            "dockHeight",
                            setothers,
                            others
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Dock Height</div>
                        {dockHeight && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          iftaCompliant ? `${styles.tag27} ${styles.tag11}` : ""
                        }`}
                        onClick={() => {
                          setIftaCompliant(!iftaCompliant);
                          setFilters(
                            iftaCompliant,
                            "iftaCompliant",
                            setothers,
                            others
                          );
                        }}
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>IFTA Compliant</div>
                        {iftaCompliant && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>
                          Reservation Requirements:
                        </div>
                      </h5>
                    </div>
                    <div className={styles.tagParent}>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          reservationRequirementsYes
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() =>
                          handleTagClick("reservationRequirementsYes")
                        }
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Yes</div>
                        {reservationRequirementsYes && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          reservationRequirementsNo
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() =>
                          handleTagClick("reservationRequirementsNo")
                        }
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>No</div>
                        {reservationRequirementsNo && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.tag11} float-left m-2 ${
                          reservationRequirementsEither
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                        onClick={() =>
                          handleTagClick("reservationRequirementsEither")
                        }
                      >
                        <img
                          className={styles.earnRevenueFromYourIdleFl}
                          alt=""
                          src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                        />
                        <div className={styles.reefer}>Either</div>
                        {reservationRequirementsEither && (
                          <img
                            className={styles.iconsolidx1}
                            alt=""
                            src="/generics/close.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.rectangleDiv} />
                  <div className={`${styles.userNameContainer} w-100`}>
                    <div className={styles.userName1}>Manufacturer</div>
                    <div className={`${styles.frameParent11}`}>
                      <div className={styles.inputTextParent11}>
                        <div className={styles.checkboxParent}>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("Ford") ? true : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "Ford",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>Ford</div>
                          </div>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("Chevrolet")
                                  ? true
                                  : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "Chevrolet",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>
                              Chevrolet
                            </div>
                          </div>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("Ram") ? true : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "Ram",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>Ram</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.inputTextParent11}>
                        <div className={styles.inputText40}>Length</div>
                        <div className={styles.checkboxParent}>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("GMC") ? true : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "GMC",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>GMC</div>
                          </div>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("Toyota") ? true : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "Toyota",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>Toyota</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.inputTextParent11}>
                        <div className={styles.checkboxParent}>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("Dodge") ? true : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "Dodge",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>Dodge</div>
                          </div>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("Jeep") ? true : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "Jeep",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>Jeep</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.inputTextParent11}>
                        <div className={styles.inputText40}>Length</div>
                        <div className={styles.checkboxParent}>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("Nissan") ? true : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "Nissan",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>Nissan</div>
                          </div>
                          <div className={styles.checkbox}>
                            <input
                              checked={
                                menufacturer.includes("Mitsubishi")
                                  ? true
                                  : false
                              }
                              className={styles.checkbox1}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const isChecked = event.target.checked;
                                setFilters(
                                  !isChecked,
                                  "Mitsubishi",
                                  setmenufacturer,
                                  menufacturer
                                );
                              }}
                              type="checkbox"
                            />
                            <div className={styles.tandemTractor}>
                              Mitsubishi
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.rectangleDiv} />
                  <div className={styles.frameChild30} />
                  <div className={styles.userNameParent1}>
                    <div className={styles.userName5}>Additional</div>
                    <div className={styles.frameParent12}>
                      <div className={styles.inputTextParent15}>
                        <div className={styles.inputText44}>
                          Premium owner only
                        </div>
                        <div className={styles.inputText45}>
                          Premium shows proven companies that have passed all
                          verifications and are recommended by drivers who have
                          an excellent rating
                        </div>
                      </div>
                      <div className={styles.toggle}>
                        <input
                          className={styles.checkbox2}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setPremiumOwnerOnly(event.target.checked);
                          }}
                          type="checkbox"
                        />
                        <div className={styles.on}>On</div>
                      </div>
                    </div>
                    <div className={styles.frameParent12}>
                      <div className={styles.inputTextParent15}>
                        <div className={`${styles.inputText44} mt-5`}>
                          Managing my filters
                        </div>
                        <div className={styles.inputText45}>
                          Premium shows proven companies that have passed all
                          verifications and are recommended by drivers who have
                          an excellent rating
                        </div>
                      </div>
                      <div className={styles.toggle}>
                        <input
                          className={styles.checkbox2}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setManagingMyFilters(event.target.checked);
                          }}
                          type="checkbox"
                        />
                        <div className={styles.on}>On</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Modal.Body>
          <div className="border-top mt-5">
            <div
              className={`${styles.button15} hoverButtons col-lg-2 m-4 col-11`}
              onClick={resetAllStates}
              style={{ float: "left" }}
            >
              <div className={styles.text}>Clear all</div>
            </div>

            <div
              className={`${styles.button15} hoverButtons offset-3 col-lg-3 m-4 col-11`}
              style={{ float: "left", width: "auto" }}
            >
              <img
                className={styles.iconoutlinesearch}
                alt=""
                src="generics/mdicardsheartoutline.svg"
              />
              <div className={styles.text}>Save filters</div>
            </div>

            <div
              className={`${styles.button14} hoverButtons col-lg-2 m-4 col-11`}
              onClick={handleClose}
              style={{ float: "right" }}
            >
              <div className={styles.text}>Show offers</div>
            </div>
          </div>
        </div>
      </Modal>
      {/* <div className={styles.mapPageFilter}>
        <div className={`${styles.dialogWindow}`}>

          <div className={styles.rectangleGroup}>
            <div className={styles.frameChild29} />
            <div className={styles.userNameGroup}>
            </div>
---------------------------------------------------------------------------------------------

              

            <div className={styles.frameChild33} />
          </div>
          <div className={styles.footer}>
            <div
              className={`${styles.button14} hoverButtons`}
              onClick={handleClose}
            >
              <div className={styles.text}>Show offers</div>
            </div>
            <div className={`${styles.button15} hoverButtons`}>
              <img
                className={styles.iconoutlinesearch}
                alt=""
                src="generics/mdicardsheartoutline.svg"
              />
              <div className={styles.text}>Save filters</div>
            </div>
            <div
              className={`${styles.button16} hoverButtons`}
              onClick={resetAllStates}
            >
              <div className={styles.text}>Clear all</div>
            </div>
          </div>
        </div>
      </div>
      {isDropdownMenuMultiSelectOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdownMenuMultiSelect}
        >
          <DropdownMenuMultiSelect onClose={closeDropdownMenuMultiSelect} />
        </PortalPopup>
      )}
      {isDropdownMenuMultiSelect1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdownMenuMultiSelect1}
        >
          <DropdownMenuMultiSelect onClose={closeDropdownMenuMultiSelect1} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default MapPageFilter;
