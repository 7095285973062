import React, { useEffect, useState } from "react";

import styles2 from "../../Map_Page/MapPage.module.css";
import AdminSidebar from "../AdminSidebar";
import AdminNavBar from "../AdminNavBar";
import styles from "./Reservations.module.css";
import carouselStyles from "../../Map_Page/MapPage.module.css";
import Carousel from "react-bootstrap/Carousel";
import { REACT_APP_TRUCK_API_BASE_URL } from "../../../utils/UrlConstants";
import axios from "axios";
import {
  errorToast,
  successToast,
} from "../../../components/Toast/ToastMessage";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

type ImageUrl = string;

const Reservations: React.FC = () => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}/${month}/${day}, ${hours}:${minutes}`;
  };

  const currentUser = useSelector((state: any) => state.currentUser);
  console.log('----currentUser', currentUser)
  const [truckList, settruckList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [trucks, setTrucks] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [bookedList, setBookings] = useState<any>([]);
  const [allTrucksId, setAllTrucksId] = useState<any>([]);
  const [specificTruck, setSpecificTruck] = useState<any>([]);

  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  useEffect(() => {
    fetchTrucks();
    getBookings();
  }, [])

  const fetchTrucks = async (query: any = null) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks`;

    try {
      const response = await axios.get(
        url?.replace(/ /g, "")?.replace(/,,/g, "")?.replace(/&&/g, "&")
      );

      console.log("all trucks vvv");
      console.log(response.data.trucks._id);

      const truckIds = response.data.trucks.map((truck: any) => truck._id);
      setAllTrucksId(truckIds);
    } catch (error) {
      errorToast("Error fetching trucks");
      console.error("Error fetching trucks:", error);
    }
  };

  const getBookings = async () => {
    setLoading(true);
    const url = `${REACT_APP_TRUCK_API_BASE_URL}/bookings`;
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    try {
      console.log(url);
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": accessToken,
          "X-Refresh-Token": refreshToken,
        },
      });

      console.log("Booked stuff vvvvv");
      console.log(response);

      if (response.status === 200) {
        setBookings(response.data.bookings);
      }
    } catch (error: any) {
      console.log(error.response?.status);
      if (error.response?.status === 401) {
        console.log(error.response);
      }
      if (error.response?.status === 403) {
        console.log(error.response);
        return;
      }
      if (error.response?.status === 404) {
        console.log(error.response);
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchTruck = async (id: string) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks/${id}`;
    try {
      const response = await axios.get(url);

      const fetchedTruck = response.data.truck;
      console.log("fetched truck from fetchTruck vvv");
      console.log(fetchedTruck);

      setTrucks((prevTrucks: any) => [...prevTrucks, fetchedTruck]);

      setImages(Object.keys(response.data.truck.images).length);
      const fetchedImages: ImageUrl[] = Object.values(
        response.data.truck.images
      );
      const updatedImages: ImageUrl[] = [...fetchedImages];
      setImages(updatedImages);
      console.log(images);
      setLoading(false);
      return fetchedTruck;
    } catch (error) {
      console.error(error, error);
    }
  };

  const bookingAcceptTruck = async (id: string) => {
    setLoading(true);
    const url = `${REACT_APP_TRUCK_API_BASE_URL}/bookings/${id}/accept`;

    try {
      const response = await axios.patch(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": accessToken,
            "X-Refresh-Token": refreshToken,
          },
        }
      );
      successToast("Booking accepted")

      if (response.data.error) {
        errorToast(response.data.error);
      } else {
        settruckList(response.data.bookings);
        setLoading(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        errorToast("Unauthorized 401");
      } else {
        errorToast(error?.response?.data?.error || 'An error occured');
      }
    }
  };

  const bookingRejectTruck = async (id: string) => {
    setLoading(true);
    const url = `${REACT_APP_TRUCK_API_BASE_URL}/bookings/${id}/cancel-by-owner`;

    try {
      const response = await axios.patch(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": accessToken,
            "X-Refresh-Token": refreshToken,
          },
        }
      );
      successToast("Booking declined")

      if (response.data.error) {
        errorToast(response.data.error);
      } else {
        settruckList(response.data.bookings);
        setLoading(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        errorToast("Unauthorized 401");
      } else {
        errorToast(error?.response?.data?.error || 'An error occured');
      }
    }
  };

  return (
    <>
      <div className={styles.adminNavBar}>
        <AdminNavBar />
      </div>
      <div style={{ display: "flex" }}>
        <AdminSidebar />
        <div className={styles.allContent}>
          <div className={styles.head}>
            <span className={styles.lightHeadText}>Ads/</span>{" "}
            <span className={styles.headText}>Reservation</span>
            <small className={styles.smallHeadText}>
              Hey there! This baby is a powerhouse, with a
            </small>
          </div>

          <div
            className={`${styles.imageContainer}`}
            style={{ display: "flex" }}
          >
            <div className={styles.subDiv}>
              <span>Bookings Pending</span>
              <h4>{ bookedList?.filter((b :any)=> {if(b.status == 'Requested') return b;} ).length || 0 }</h4>
            </div>
            <div className={styles.subDiv}>
              <span>???</span>
              <h4>???</h4>
            </div>
            <div className={styles.subDiv}>
              <span>???</span>
              <h4>???</h4>
            </div>
            <div className={styles.subDiv}>
              <span>Current invoices</span>
              <h4>0</h4>
            </div>
            <div className={styles.subDiv}>
              <span>Current invoices</span>
              <h4>0</h4>
            </div>
          </div>

          {
            bookedList?.map((booking: any, index: number) => {             
              const bookedTruck = booking.truckId
              return (
                <div className={`${styles.imageContainer}`} key={index}>
                  <div className={`${styles.verticleCard} col-3`}>
                    <Carousel
                      className={`${carouselStyles?.vecicleCard2} ${styles.verticleCard}`}
                      id="truck_container"
                      interval={null}
                    >
                      {bookedTruck && bookedTruck.images ? (
                        Object.entries(bookedTruck.images).map(
                          ([key, image], imgIndex) => {
                            console.log("Truck");
                            return (
                              <Carousel.Item
                                key={`${booking.truckId}-${imgIndex}`}
                              >
                                <div
                                  className={`${
                                    styles2.frameParent3 || "frame-24031@3x.png"
                                  } hoverButtons`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Link to={`/truck/${bookedTruck?._id}`}>
                                    <img
                                      src={
                                        typeof image === "string"
                                          ? image
                                          : "frame-24031@3x.png"
                                      }
                                      className={`${styles2.frameParent3} hoverButtons`}
                                      style={{ width: "100%" }}
                                      alt={key}
                                    />
                                  </Link>
                                </div>
                              </Carousel.Item>
                            );
                          }
                        )
                      ) : (
                        <Carousel.Item>
                          <div>No images available</div>
                        </Carousel.Item>
                      )}
                    </Carousel>
                  </div>
                  <div
                    className={`${styles.imageContainerRight} col-lg-9 col-sm-6`}
                  >
                    <span>
                      Book type: <b>{booking?.agreementType}&nbsp;</b>
                    </span>
                    <br />
                    <span>Truck ID: {bookedTruck?._id}</span>
                    <span className="d-block">
                      <span className={styles.imageContainerRightText}>
                        {bookedTruck?.year} &nbsp; {bookedTruck?.manufacturer}{" "}
                        &nbsp; {bookedTruck?.model} &nbsp;
                      </span>

                      <span className={styles.dot}></span>
                      <span className={styles.imageContainerRightText}>
                        {bookedTruck?.vehicleSubtype}
                      </span>
                      <span className={styles.dot}></span>
                      <span className={styles.imageContainerRightText}>
                        ${bookedTruck?.rentPerDay} / day{" "}
                      </span>

                      <span className={styles.activeButton}>{booking?.status}</span>
                    </span>
                    <div className={styles.border}></div>
                    <span className={styles.imageContainerLightColorText}>
                      Start Date:{" "}
                      {booking?.startDate ? formatDate(booking?.startDate) : "N/A"}
                    </span>{" "}
                    <br />
                    <span className={styles.imageContainerLightColorText}>
                      End Date:{" "}
                      {booking?.endDate ? formatDate(booking?.endDate) : "N/A"}
                    </span>{" "}
                    <div className={styles.border}></div>
                    <span className={styles.imageContainerLightColorText}>
                      Post by:{" "}
                    </span>{" "}
                    <span className={styles.imageContainerLightText}>???</span>
                    {
                      currentUser.role == 'renter' && (
                        <span className="d-block ">
                          <span
                            className="d-block "
                          >
                            <span
                              className={`${styles.imageContainerAccept} ${styles.declineTypography} hoverButtons`}
                              onClick={() => bookingAcceptTruck(booking._id)}
                            >
                              <svg
                                width="14"
                                height="10"
                                viewBox="0 0 14 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mx-1"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
                                  fill="white"
                                />
                              </svg>
                              Accept
                            </span>
                            <span
                              className={`${styles.imageContainerDecline} ${styles.declineTypography} hoverButtons`}
                              onClick={() => bookingRejectTruck(booking._id)}
                            >
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mx-1"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                                  fill="#0F172A"
                                />
                              </svg>
                              Decline
                            </span>
                          </span>
                        </span>
                      )
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </>
  );
};

export default Reservations;
